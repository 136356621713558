<template>
    <div class="theContact">
        <!-- 头部大图 -->
        <div
            class="tcTopBimg"
            :style="`background: url(${getAdvData(10).children ? getAdvData(10).children[0].image : ''}) no-repeat center;`"
        >
            <a :href="getAdvData(10).children[0].url" target="_blank" rel="noopener noreferrer"></a>
        </div>
        <!-- 底部信息 -->
        <div class="tcBottom">
            <!-- 标题 -->
            <div class="tcbTitle">
                <p class="tctBig">联系威有</p>
                <p class="tctBox"></p>
                <p class="tctSmall">威有信仰并一直坚持，为客户打造真正有价值的互联网平台</p>
            </div>
            <!-- 3个盒子 -->
            <div class="tcbThree">
                <ul>
                    <li class="tcbLi">
                        <div class="tcbIcon" @mousemove="showMove(1)" @mouseleave="showLea(1)">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="tiImg" v-show="showIcon === 1" />
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="tiImg12" v-show="showIcon === 2" />
                        </div>
                        <div class="tcbText">{{ getWebData().company_address }}</div>
                    </li>
                    <li class="tcbLi">
                        <div class="tcbIcon" @mousemove="showMove(2)" @mouseleave="showLea(2)">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" v-show="showIcon2 === 1" alt="" class="tiImg2" />
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" v-show="showIcon2 === 2" alt="" class="tiImg22" />
                        </div>
                        <div class="tcbText">{{ getWebData().phone }}</div>
                    </li>
                    <li class="tcbLi" @click="open">
                        <div class="tcbIcon" @mousemove="showMove(3)" @mouseleave="showLea(3)">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" v-show="showIcon3 === 1" alt="" class="tiImg3" />
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" v-show="showIcon3 === 2" alt="" class="tiImg33" />
                        </div>
                        <div class="tcbText">我要开发</div>
                    </li>
                </ul>
            </div>
            <!-- 信息与地图 -->
            <div class="tcbMapBox">
                <div class="cmbLeft">
                    <div class="cmbT1">
                        {{ getWebData().introduce }}
                    </div>
                    <div class="cmbT2">
                        <div class="ct2T">
                            <div class="ct2Box" v-if="ll[0]">
                                <p>
                                    <img :src="ll[0].icon" v-if="ll[0].icon" />
                                    <span v-else>{{ ll[0].title }}</span>
                                </p>
                                <img v-lazy="ll[0].image" alt="" />
                            </div>
                            <div class="ct2Box" v-if="ll[1]">
                                <p>
                                    <img :src="ll[1].icon" v-if="ll[1].icon" />
                                    <span v-else>{{ ll[1].title }}</span>
                                </p>
                                <img v-lazy="ll[1].image" alt="" />
                            </div>
                            <div class="ct2Box" v-if="ll[2]">
                                <p>
                                    <img :src="ll[2].icon" v-if="ll[2].icon" />
                                    <span v-else>{{ ll[2].title }}</span>
                                </p>
                                <img v-lazy="ll[2].image" alt="" />
                            </div>
                        </div>
                        <div class="ct2B">
                            <div class="ct2More" @click="goPage">
                                <div class="cmTop"></div>
                                <p class="cmp">我要机遇</p>
                                <div class="cmBottom"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cmbRight">
                    <!-- 地图 -->
                    <div class="cmbMap">
                        <div id="container" style="width: 500px; height: 300px"></div>
                    </div>
                </div>
            </div>
            <!-- 弹出层 -->
            <VuForm page="我要开发" ref="form"></VuForm>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VuForm from "./vuForm.vue";

export default {
    name: "TheContact",
    components: { VuForm },
    inject: ["getWebData", "getAdvData", "addUserBehaviorL"],
    data() {
        return {
            showIcon: 1,
            showIcon2: 1,
            showIcon3: 1,
            topBgUrl: "http://iv.okvu.cn/vugw/img/vu818lxvu.jpg",
            map: {},
            ll: [],
        };
    },
    mounted() {
        let timer = null;
        timer = setInterval(() => {
            if (this.getWebData().company_address) {
                this.init();
                clearInterval(timer);
            }
        }, 200);
        this.getLlvy();
    },
    methods: {
        // 跳转页面
        goPage() {
            this.$router.push("/partner3");
        },
        showMove(i) {
            switch (i) {
                case 1:
                    this.showIcon = 2;
                    break;
                case 2:
                    this.showIcon2 = 2;
                    break;
                case 3:
                    this.showIcon3 = 2;
                    break;
            }
        },
        showLea(i) {
            switch (i) {
                case 1:
                    this.showIcon = 1;
                    break;
                case 2:
                    this.showIcon2 = 1;
                    break;
                case 3:
                    this.showIcon3 = 1;
                    break;
            }
        },
        init() {
            var map = new BMapGL.Map("container");
            this.map = map;
            map.centerAndZoom(new BMapGL.Point(113.927539, 22.942889), 16); // 初始化地图,设置中心点坐标和地图级别
            map.enableScrollWheelZoom(true);

            // 红点
            var point = new BMapGL.Point(113.927539, 22.942889);
            var marker = new BMapGL.Marker(point); // 创建标注
            map.addOverlay(marker);
            marker.addEventListener("click", function (e) {
                map.openInfoWindow(infoWindow, new BMapGL.Point(113.927539, 22.942889));
            });

            // 信息窗口
            var opts = {
                width: 250,
                height: 100,
                title: `<p style="font-size:14px">
                            <img src="https://iv.okvu.cn/vugw/img/vu818logo.png" style="width:15px;vertical-align: middle;"/>
                            <span>威有科技（东莞）有限公司</span>
                        </p>`,
                offset: new BMapGL.Size(0, -25),
            };
            var infoWindow = new BMapGL.InfoWindow(
                `
                <p>地址：${this.getWebData().company_address}</p>
                <p>电话：${this.getWebData().phone}</p> 
                <p></p>
            `,
                opts
            ); // 创建信息窗口对象
            infoWindow.addEventListener("open", function (e) {
                let t = document.querySelector(".BMap_bubble_top");
                // console.log(t);
                t.style.borderBottom = "1px solid black";
            });
            map.openInfoWindow(infoWindow, new BMapGL.Point(113.927539, 22.942889));
        },
        // 显示表单
        open() {
            this.$refs["form"].showTcbs();
            this.addUserBehaviorL("联系威有点击我要开发");
        },
        async getLlvy() {
            let { data } = await axios.get("api/website/llvy_show", { params: { tag: 2 } });
            this.ll = data;
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped>
.theContact {
    width: 100%;
    // 顶部大图
    .tcTopBimg {
        width: 100%;
        height: 400px;
        position: relative;
        background-size: auto 100% !important;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    // 底部信息
    .tcBottom {
        width: 1280px;
        height: 100%;
        margin: 50px auto 0;
        margin-top: 50px;
        .tcbTitle {
            width: 40%;
            // height: 120px;
            text-align: center;
            margin: 0 auto;
            .tctBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
            }
            .tctSmall {
                width: 100%;
                height: 50px;
                line-height: 50px;
            }
        }
        // 三个盒子
        .tcbThree {
            width: 75%;
            height: 200px;
            margin: 0 auto;
            margin-top: 50px;
            position: relative;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-evenly;

                .tcbLi {
                    width: 200px;
                    height: 100%;
                    .tcbIcon {
                        width: 80px;
                        height: 80px;
                        margin: 0 auto;
                        border-radius: 20px;
                        // border: 3px solid #7781f1;
                        box-shadow: 2px 2px 10px 2px gainsboro;
                        cursor: pointer;
                        text-align: center;
                        padding-top: 15px;
                        box-sizing: border-box;
                        position: relative;
                        overflow: hidden;
                        .tiImg {
                            position: absolute;
                            top: -675px;
                            left: -285px;
                        }
                        .tiImg12 {
                            position: absolute;
                            top: -742px;
                            left: -285px;
                        }
                        .tiImg2 {
                            position: absolute;
                            top: -676px;
                            left: -359px;
                        }
                        .tiImg22 {
                            position: absolute;
                            top: -744px;
                            left: -359px;
                        }
                        .tiImg3 {
                            width: 1600px;
                            position: absolute;
                            top: -698px;
                            left: -442px;
                        }
                        .tiImg33 {
                            width: 1600px;
                            position: absolute;
                            top: -770px;
                            left: -442px;
                        }
                        .iconfont {
                            font-size: 60px;
                            color: #7781f1;
                        }
                    }
                    .tcbIcon:hover {
                        background: linear-gradient(to right, #7781f1, #7781f1e0);
                        .iconfont {
                            color: white;
                        }
                    }
                    .tcbText {
                        width: 100%;
                        height: 30px;
                        margin-top: 30px;
                        line-height: 30px;
                        font-weight: 900;
                        text-align: center;
                    }
                }
            }
        }
        // 地图
        .tcbMapBox {
            width: 100%;
            height: 400px;
            margin-top: 30px;
            .cmbLeft {
                width: 48%;
                height: 100%;
                float: left;
                .cmbT1 {
                    width: 100%;
                    height: 60px;
                    line-height: 30px;
                    font-size: 15px;
                    text-indent: 45px;
                    text-align: left;
                }
                .cmbT2 {
                    width: 600px;
                    height: 270px;
                    text-align: center;
                    margin-top: 50px;
                    .ct2T {
                        width: 100%;
                        height: 200px;
                        .ct2Box {
                            width: 200px;
                            height: 200px;
                            float: left;
                            p {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                margin-bottom: 5px;
                                img {
                                    // background-color: black;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            & > img {
                                width: 80%;
                                height: 80%;
                                object-fit: contain;
                                border-radius: 5px;
                                // border: 1px solid black;
                                box-shadow: 2px 2px 10px 2px gainsboro;
                                padding: 10px;
                            }
                        }
                    }
                    .ct2B {
                        width: 100%;
                        height: 70px;
                        margin-top: 30px;
                        .ct2More {
                            width: 160px;
                            height: 80px;
                            text-align: center;
                            margin: 0 auto;
                            cursor: pointer;
                            font-weight: bold;
                            .cmp {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                padding-top: 5px;
                            }
                            .cmTop {
                                width: 100%;
                                position: relative;
                                transition: 0.5s;
                            }
                            .cmTop::before {
                                border-width: 1px 0 0 1px;
                                border-color: #56c5ff;
                                left: 20px;
                                content: "";
                                position: absolute;
                                width: 7px;
                                height: 7px;
                                border-style: solid;

                                transition-duration: 0.3s;

                                transform: translateZ(0);
                            }
                            .cmTop::after {
                                border-width: 1px 1px 0 0;
                                border-color: #56c5ff;
                                right: 20px;
                                content: "";
                                position: absolute;
                                width: 7px;
                                height: 7px;
                                border-style: solid;

                                transition-duration: 0.3s;

                                transform: translateZ(0);
                            }
                            .cmBottom {
                                width: 100%;
                                position: relative;
                                transition: 0.5s;
                            }
                            .cmBottom::before {
                                border-width: 0 0 1px 1px;
                                border-color: #56c5ff;
                                left: 20px;
                                content: "";
                                position: absolute;
                                width: 7px;
                                height: 7px;
                                border-style: solid;

                                transition-duration: 0.3s;

                                transform: translateZ(0);
                            }
                            .cmBottom::after {
                                border-width: 0 1px 1px 0;
                                border-color: #56c5ff;
                                right: 20px;
                                content: "";
                                position: absolute;
                                width: 7px;
                                height: 7px;
                                border-style: solid;
                                transition-duration: 0.3s;
                                transform: translateZ(0);
                            }
                        }
                        .ct2More:hover {
                            .cmTop {
                                width: 180px;
                                transform: translateY(-10px) translateX(-10px);
                            }
                            .cmBottom {
                                width: 180px;
                                transform: translateY(10px) translateX(-10px);
                            }
                        }
                    }
                }
            }
            .cmbRight {
                width: 48%;
                height: 100%;
                float: right;
                .cmbMap {
                    width: 500px;
                    height: 300px;
                    border-radius: 20px;
                    #container {
                        border-radius: 20px;
                        z-index: 1;
                    }
                }
            }
        }
    }
}
.vy {
    background-color: red;
    &::before {
        content: " ";
        position: absolute;
        height: 1px;
        width: 30px;
        background-color: black;
    }
}
</style>
