<template>
  <!-- 联系威有 -->
  <div class="contact">
    <!-- 内容 -->
    <TheContact></TheContact>
  </div>
</template>

<script>
import TheContact from "../components/content/TheContact/TheContact.vue";

export default {
  name: "Contact",
  components: {
    TheContact,
  },
};
</script>

<style lang="less" scoped>
.contact {
  margin: 0 auto;
}
</style>
